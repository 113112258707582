html, body {
  min-height: 100vh;
  background-color: #282c34;
  color: #fff;
}

a {
  color: #fff;
}

img {
  max-width: calc(100% - 2ex);
  padding: 1ex;
}

.header {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}
img.true-color-image {
  display: inline-block;
  max-width: calc(max(49vw, min(45ex, 100vw)) - 2ex);
  max-height: calc(max(49vh, min(45ex, 100vh)) - 2ex);
}
.header .metadata {
  display: inline-block;
  max-width: 49vw;
}

.results {
  /* display: flex; */
  user-select: none;
}

.result {
  display: inline-block;
  position: relative;
  max-width: calc(max(80ex, 48vw));
  background-color: #3a3c39;
  border: 1px solid #222;
  margin: 1ex;
  padding: 0;
  border-radius: 4px;
}
.result progress {
  padding: 0;
  height: 1em;
  margin: 0;
  width: 100%;
  border: 0;
}

.result progress::-moz-progress-bar { background: #0066ff; }
.result progress::-webkit-progress-value { background: #0066ff; }
.result progress { color: #0066ff; }

.result img {
  border-radius: 4px 4px 0 0;
  max-width: 100%;
  padding: 0;
  margin: 0;
}
.result .data-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.result-data {
  margin: 0;
  padding: 1ex;
  /* border-top: 1px solid #222; */
}

.header {
  font-size: xx-large;
  margin-top: 2rem;
}

.metadata {
  margin-bottom: 2rem;
}

.metadata ul {
  font-weight: bold;
  text-align: left;
  width: fit-content;
  margin: 0 auto;
}

.App {
  text-align: center;
}

.App canvas {
  pointer-events: none;
  max-height: 80vh;
  max-width: 80vw;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hidden-slot-placeholder {
  visibility: hidden;
}

@media (max-width: 100ex) {
  .header .metadata {
    max-width: 100vw;
    font-size: x-large;
  }

  .header h2 {
    font-size: x-large;
  }
}

